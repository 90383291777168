import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectLocationsByKeywordsDataSources } from "src/store/selectors";

export const useLocationId = ({
  keywordsDataSources = [],
  locationId: defaultLocationId = null,
}: {
  locationId?: Nullish<Location.Data["id"]>;
  keywordsDataSources?: Nullable<Search.KeywordsDataSource>[];
}): [
  Nullable<Location.Data["id"]>,
  Dispatch<SetStateAction<Nullable<Location.Data["id"]>>>,
] => {
  const formattedKeywordsDataSources = useMemo<
    Search.KeywordsDataSource[]
  >(() => {
    const dataSources = new Set<Search.KeywordsDataSource>();

    for (const dataSource of keywordsDataSources)
      if (dataSource) dataSources.add(dataSource);

    return Array.from(dataSources);
  }, [keywordsDataSources]);

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSources(state, formattedKeywordsDataSources),
  );

  const defaultLocation = useMemo<Location.Data | null>(
    () => locations[0] || null,
    [locations],
  );

  const [locationId, setLocationId] =
    useState<Nullable<Location.Data["id"]>>(null);

  useEffect(() => {
    if (defaultLocationId) setLocationId(defaultLocationId);
  }, [defaultLocationId]);

  useEffect(() => {
    if (!locationId || !keywordsDataSources.length) return;

    const isLocationAvailable = locations.some(({ id }) => id === locationId);

    if (!isLocationAvailable && defaultLocation)
      setLocationId(defaultLocation.id);
  }, [locations, locationId, keywordsDataSources.length, defaultLocation]);

  return [locationId, setLocationId];
};
