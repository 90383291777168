import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { PRIORITIZED_LOCATION_IDS } from "src/constants";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./locationsApi";

export const locationsAdapter = createEntityAdapter<Location.Data>({
  sortComparer: locationsDefaultSort,
});

const initialState = locationsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllLocations = createAsyncThunk<Location.Data[]>(
  "locations/fetch-all",
  api.getLocations,
);

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllLocations.pending, onStatePending);
    builder.addCase(fetchAllLocations.rejected, onStateRejected);
    builder.addCase(fetchAllLocations.fulfilled, (...args) => {
      onStateFulfilled(...args);
      locationsAdapter.setAll(...args);
    });
  },
});

export default locationsSlice.reducer;

function locationsDefaultSort(a: Location.Data, b: Location.Data): number {
  const prioritizedLocationIds = new Set(PRIORITIZED_LOCATION_IDS);

  const [isAPrioritizedLocation, isBPrioritizedLocation] = [
    prioritizedLocationIds.has(a.id),
    prioritizedLocationIds.has(b.id),
  ];

  if (isAPrioritizedLocation !== isBPrioritizedLocation)
    return isAPrioritizedLocation ? -1 : 1;

  const [[countryA, regionA, cityA], [countryB, regionB, cityB]] = [
    [a.country || "", a.region || "", a.city || ""],
    [b.country || "", b.region || "", b.city || ""],
  ];

  return (
    countryA.localeCompare(countryB) ||
    regionA.localeCompare(regionB) ||
    cityA.localeCompare(cityB)
  );
}
