import { generateDocId } from "src/store/utils";
import {
  SEARCH_DEFAULT_STATUS,
  SEARCH_DEFAULT_KEYWORDS_SELECTION_METHOD,
} from "src/constants";

export const formatNewTrackerName = (
  name: Tracker.Data["name"],
  location: string,
): Tracker.Data["name"] => `${name} - ${location}`;

export const getDefaultSuggestedSearch = ({
  subject,
  description,
  locationId,
  languageId,
  keywordsDataSource,
  keywordsSelectionMethod = SEARCH_DEFAULT_KEYWORDS_SELECTION_METHOD,
}: {
  subject: string;
  description: string;
  locationId: Location.Data["id"];
  languageId: Language.Data["id"];
  keywordsDataSource: Search.KeywordsDataSource;
  keywordsSelectionMethod?: Search.KeywordsSelectionMethod;
}): Search.CreationData => ({
  subject,
  locationId,
  languageId,
  description,
  keywordsDataSource,
  id: generateDocId(),
  keywordsSelectionMethod,
  status: SEARCH_DEFAULT_STATUS,
});
