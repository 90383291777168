import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./TrackerStatusIcon.module.scss";
import { Tooltip } from "src/components";
import { selectApplicationInfo } from "src/store/selectors";
import { ExclamationCircle, Warning } from "src/assets/icons";

type Props = {
  trackerId: Tracker.Data["id"];
};

export const TrackerStatusIcon: FC<Props> = ({ trackerId }) => {
  const { t } = useTranslation();

  const { trackersWithPartialData } = useSelector(selectApplicationInfo);

  const trackerStatus = useMemo<Search.Status | null>(
    () => trackersWithPartialData[trackerId] || null,
    [trackerId, trackersWithPartialData],
  );

  const Icon = useMemo<JSX.Element | null>(() => {
    switch (trackerStatus) {
      case "NO_SELECTED_KEYWORDS":
        return <Warning className={styles.errorIcon} />;
      case "INCOMPLETE_DATA":
        return <ExclamationCircle className={styles.warningIcon} />;
      default:
        return null;
    }
  }, [trackerStatus]);

  if (!Icon) return null;

  return (
    <Tooltip
      content={t("component.tracker_status_icon.tooltip.empty_keywords")}
    >
      {Icon}
    </Tooltip>
  );
};
