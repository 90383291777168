import { FC, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./GlobalSearch.module.scss";
import { TrashOutline, User } from "src/assets/icons";
import { useModal, useTrackerBlocker } from "src/hooks";
import { SelectOption } from "src/components/selectors/Select/types";
import {
  ROUTES,
  TRACKER_SORT_OPTIONS,
  SEARCH_KEYWORDS_DATA_SOURCE_MAP,
} from "src/constants";
import {
  sortTrackers,
  getIconByName,
  filterTrackers,
  sortDashboards,
  getUserFullName,
  filterDashboards,
  isKeywordsDataSourceTypeGuard,
  formatDashboardsWithKeywordsDataSources,
} from "src/utils";
import {
  Button,
  Select,
  Sidebar,
  Tooltip,
  InputWithIcon,
} from "src/components";
import {
  selectUser,
  selectCompanyMembers,
  selectApplicationInfo,
  selectCompanyTrackers,
  selectVisibleDashboards,
  selectTrackersCollections,
  selectSubscriptionPlanKeywordsDataSources,
} from "src/store/selectors";
import {
  TrackerStatusIcon,
  ConfirmDeleteTrackerModal,
  ConfirmDashboardDeletionModal,
  TrackerKeywordsDataSourcesIcon,
  DashboardKeywordsDataSourcesIcon,
} from "src/features";

// Inner imports
import type { GlobalSearchProps } from "./types";

export const GlobalSearch: FC<GlobalSearchProps> = ({
  isOpened,
  setIsOpened,
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  const { setModal } = useModal();

  const { hasTrackerAccess, showSubscriptionPlanLimitNotification } =
    useTrackerBlocker();

  const user = useSelector(selectUser);

  const trackers = useSelector(selectCompanyTrackers);

  const trackersCollections = useSelector(selectTrackersCollections);

  const dashboards = useSelector(selectVisibleDashboards);

  const { trackersWithPartialData } = useSelector(selectApplicationInfo);

  const companyMembers = useSelector(selectCompanyMembers);

  const subscriptionPlanKeywordsDataSources = useSelector(
    selectSubscriptionPlanKeywordsDataSources,
  );

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [sortType, setSortType] = useState<Tracker.ListSort>("lastViewed");

  const [filterMemberId, setFilterMemberId] =
    useState<Company.Member["id"]>("all");

  const [filterKeywordsDataSource, setFilterKeywordsDataSource] = useState<
    Search.KeywordsDataSource | "all"
  >("all");

  const keywordsDataSourceOptions = useMemo<SelectOption[]>(() => {
    const options = new Set<SelectOption>();

    options.add({
      value: "all",
      label: t("component.sidebar.global_search.form.data_source.all"),
    });

    for (const dataSource of subscriptionPlanKeywordsDataSources) {
      if (!isKeywordsDataSourceTypeGuard(dataSource)) continue;

      options.add({
        value: dataSource,
        label: t(SEARCH_KEYWORDS_DATA_SOURCE_MAP[dataSource].labelKey),
        renderIcon: () =>
          getIconByName(SEARCH_KEYWORDS_DATA_SOURCE_MAP[dataSource].icon),
      });
    }

    return Array.from(options);
  }, [subscriptionPlanKeywordsDataSources, t]);

  const companyMemberOptions = useMemo<SelectOption[]>(() => {
    const options = new Set<SelectOption>();

    options.add({
      value: "all",
      label: t("component.sidebar.global_search.form.author_id.all"),
    });

    const memberOptions = new Set<SelectOption>();

    for (const memberId in companyMembers) {
      const member = companyMembers[memberId];

      if (!member) continue;

      const memberName =
        memberId === user.id
          ? t("component.sidebar.global_search.form.author_id.current_user", {
              name: getUserFullName(member),
            })
          : getUserFullName(member);

      memberOptions.add({ value: memberId, label: memberName });
    }

    const sortedMemberOptions = Array.from(memberOptions).sort(
      (a, b) => Number(b.value === user.id) - Number(a.value === user.id),
    );

    return [...options, ...sortedMemberOptions];
  }, [companyMembers, t, user.id]);

  const formattedDashboards = useMemo<Dashboard.DataWithKeywordsDataSources[]>(
    () =>
      formatDashboardsWithKeywordsDataSources({
        trackers,
        dashboards,
        trackersCollections,
      }),
    [dashboards, trackers, trackersCollections],
  );

  const filteredDashboards = useMemo<Dashboard.Data[]>(
    () =>
      filterDashboards({
        query: searchQuery,
        authorId: filterMemberId,
        dashboards: formattedDashboards,
        keywordsDataSource: filterKeywordsDataSource,
      }),
    [
      searchQuery,
      filterMemberId,
      formattedDashboards,
      filterKeywordsDataSource,
    ],
  );

  const sortedDashboards = useMemo<Dashboard.Data[]>(
    () =>
      sortDashboards({
        type: sortType,
        dashboards: filteredDashboards,
        lastViewedDashboardIds: user.lastViewedDashboardIds,
      }),
    [filteredDashboards, sortType, user.lastViewedDashboardIds],
  );

  const filteredTrackers = useMemo<Tracker.Data[]>(
    () =>
      filterTrackers({
        trackers,
        query: searchQuery,
        authorId: filterMemberId,
        keywordsDataSource: filterKeywordsDataSource,
      }),
    [trackers, searchQuery, filterMemberId, filterKeywordsDataSource],
  );

  const sortedTrackers = useMemo<Tracker.Data[]>(
    () =>
      sortTrackers({
        type: sortType,
        trackers: filteredTrackers,
        lastViewedTrackerIds: user.lastViewedTrackerIds,
      }),
    [sortType, filteredTrackers, user.lastViewedTrackerIds],
  );

  const closeSidebar = useCallback(
    (): void => setIsOpened(false),
    [setIsOpened],
  );

  const showDashboardStatusIcon = useCallback(
    (dashboardId: Dashboard.Data["id"]): JSX.Element | null => {
      const trackersCollection = trackersCollections.find(
        ({ id }) => id === dashboardId,
      );

      if (!trackersCollection) return null;

      const trackerStatuses = trackersCollection.trackerIds.map(
        (id) => trackersWithPartialData[id],
      );

      switch (true) {
        case trackerStatuses.includes("NO_SELECTED_KEYWORDS"): {
          const trackerIdWithStatus = trackersCollection.trackerIds.find(
            (id) => trackersWithPartialData[id] === "NO_SELECTED_KEYWORDS",
          );

          if (!trackerIdWithStatus) return null;

          return <TrackerStatusIcon trackerId={trackerIdWithStatus} />;
        }

        case trackerStatuses.includes("INCOMPLETE_DATA"): {
          const trackerIdWithStatus = trackersCollection.trackerIds.find(
            (id) => trackersWithPartialData[id] === "INCOMPLETE_DATA",
          );

          if (!trackerIdWithStatus) return null;

          return <TrackerStatusIcon trackerId={trackerIdWithStatus} />;
        }

        default:
          return null;
      }
    },
    [trackersCollections, trackersWithPartialData],
  );

  const getAuthorFullName = useCallback(
    (value: User.Data["id"]): string => {
      const member = companyMembers[value];

      if (!member) return "";

      if (user.id === value)
        return t("component.sidebar.global_search.label.current_user", {
          name: getUserFullName(member),
        });

      return getUserFullName(member);
    },
    [companyMembers, t, user.id],
  );

  const onDashboardClick = (value: Dashboard.Data["id"]): void => {
    history.push(`${ROUTES.dashboardsHomePage}/${value}`);

    closeSidebar();
  };

  const onDashboardDeleteClick = (value: Dashboard.Data["id"]): void =>
    setModal(
      "confirm-dashboard-deletion",
      <ConfirmDashboardDeletionModal trackersCollectionId={value} />,
    );

  const onTrackerClick = (value: Tracker.Data["id"]): void => {
    if (!hasTrackerAccess) return showSubscriptionPlanLimitNotification();

    history.push(`${ROUTES.trackersHomePage}/edit/${value}`);

    closeSidebar();
  };

  const onTrackerDeleteClick = (value: Tracker.Data["id"]): void =>
    setModal(
      "confirm-delete-tracker",
      <ConfirmDeleteTrackerModal trackerId={value} />,
    );

  const onSortTypeChange = (value: Tracker.ListSort): void =>
    setSortType(value);

  const onChangeFilterMemberId = (value: Company.Member["id"]): void =>
    setFilterMemberId(value);

  const onChangeFilterKeywordsDataSource = (value: string): void => {
    if (!isKeywordsDataSourceTypeGuard(value))
      return setFilterKeywordsDataSource("all");

    setFilterKeywordsDataSource(value);
  };

  return (
    <Sidebar isSidebarOpen={isOpened} setIsSidebarOpen={setIsOpened}>
      <div className={styles.wrapper}>
        <form className={styles.form}>
          <div className={styles.formGroup}>
            <InputWithIcon
              tabIndex={1}
              autoFocus
              icon="Magnifier"
              hasClearButton
              value={searchQuery}
              placeholder={t(
                "component.sidebar.global_search.form.placeholder.search_query",
              )}
              changeHandler={setSearchQuery}
            />
          </div>
          <div className={styles.formGroup}>
            <Select
              icon="User"
              hasFilter={false}
              value={filterMemberId}
              options={companyMemberOptions}
              changeHandler={onChangeFilterMemberId}
            />
            <Select
              icon="Filter"
              hasFilter={false}
              options={keywordsDataSourceOptions}
              value={filterKeywordsDataSource || ""}
              changeHandler={onChangeFilterKeywordsDataSource}
            />
          </div>
          <div className={styles.formGroup}>
            <Select
              icon="Sort"
              value={sortType}
              options={TRACKER_SORT_OPTIONS}
              openingDirection="bottom-end"
              inputClassName={styles.sortInput}
              selectClassName={styles.sortSelect}
              changeHandler={(value: string) =>
                onSortTypeChange(value as Tracker.ListSort)
              }
            />
          </div>
        </form>
        <div className={styles.content}>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <span>
                {t("component.sidebar.global_search.label.dashboards")}
              </span>
            </div>
            <div className={styles.sectionItems}>
              {sortedDashboards.length ? (
                sortedDashboards.map(({ id, name, authorId }) => (
                  <div className={styles.sectionItem} key={id}>
                    <Button
                      buttonSize="large"
                      buttonStyle="transparent"
                      className={styles.itemButton}
                      onClick={() => onDashboardClick(id)}
                    >
                      <div className={styles.sectionItemIcon}>
                        <DashboardKeywordsDataSourcesIcon
                          trackersCollectionId={id}
                        />
                      </div>
                      <div className={styles.sectionItemName}>
                        <span>{name}</span>
                      </div>
                      {showDashboardStatusIcon(id)}
                    </Button>
                    <div className={styles.sectionItemActionsHover}>
                      <div className={styles.authorWrapper}>
                        <User />
                        <span>{getAuthorFullName(authorId)}</span>
                      </div>
                      <Tooltip
                        content={t(
                          "component.sidebar.global_search.tooltip.delete_dashboard",
                        )}
                      >
                        <Button
                          buttonStyle="transparent"
                          className={styles.itemDeleteButton}
                          onClick={() => onDashboardDeleteClick(id)}
                        >
                          <TrashOutline />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.placeholder}>
                  {t("component.sidebar.global_search.label.no_dashboard")}
                </div>
              )}
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.sectionTitle}>
              <span>{t("component.sidebar.global_search.label.trackers")}</span>
            </div>
            <div className={styles.sectionItems}>
              {sortedTrackers.length ? (
                sortedTrackers.map(({ id, name, authorId }) => (
                  <div className={styles.sectionItem} key={id}>
                    <Button
                      buttonSize="large"
                      buttonStyle="transparent"
                      className={styles.itemButton}
                      onClick={() => onTrackerClick(id)}
                    >
                      <div className={styles.sectionItemIcon}>
                        <TrackerKeywordsDataSourcesIcon trackerId={id} />
                      </div>
                      <div className={styles.sectionItemName}>
                        <span>{name}</span>
                        <TrackerStatusIcon trackerId={id} />
                      </div>
                    </Button>
                    <div className={styles.sectionItemActionsHover}>
                      <div className={styles.authorWrapper}>
                        <User />
                        <span>{getAuthorFullName(authorId)}</span>
                      </div>
                      <Tooltip
                        content={t(
                          "component.sidebar.global_search.tooltip.delete_tracker",
                        )}
                      >
                        <Button
                          buttonStyle="transparent"
                          className={styles.itemDeleteButton}
                          onClick={() => onTrackerDeleteClick(id)}
                        >
                          <TrashOutline />
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.placeholder}>
                  {t("page.dashboard.label.no_topics")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
