import { FC, useMemo } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SelectPicker, SelectPickerProps } from "rsuite";

import styles from "./LanguagesDropdown.module.scss";
import { Tooltip } from "src/components";
import { ChevronDown } from "src/assets/icons";
import { selectLanguagesByKeywordsDataSources } from "src/store/selectors";

type Props = Omit<SelectPickerProps, "data"> & {
  error?: string;
  isDisabled?: boolean;
  hasDefaultStyles?: boolean;
  languageId: Nullable<Language.Data["id"]>;
  keywordsDataSources: Nullable<Search.KeywordsDataSource>[];
  setLanguageId: (value: Language.Data["id"]) => void;
};

export const LanguagesDropdown: FC<Props> = ({
  error,
  languageId,
  setLanguageId,
  keywordsDataSources,
  hasDefaultStyles = false,
  isDisabled: defaultIsDisabled = false,
  ...props
}) => {
  const { t } = useTranslation();

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSources(state, keywordsDataSources),
  );

  const languagesOptions = useMemo<Option<Language.Data["id"]>[]>(
    () =>
      languages.map((language) => ({
        label: language.name,
        value: language.id,
      })),
    [languages],
  );

  const hasOneOption = useMemo<boolean>(
    () => languagesOptions.length === 1,
    [languagesOptions.length],
  );

  const isDisabled = useMemo<boolean>(
    () => defaultIsDisabled || hasOneOption,
    [defaultIsDisabled, hasOneOption],
  );

  const tooltip = useMemo<string>(() => {
    if (hasOneOption)
      return t("component.languages_dropdown.tooltip.single_option");

    return "";
  }, [hasOneOption, t]);

  const onLanguageSelect = (value: Language.Data["id"]): void =>
    setLanguageId(value);

  const onLanguageClean = (): void => setLanguageId("");

  return (
    <div
      className={cx(
        styles.wrapper,
        hasDefaultStyles ? "" : styles.updatedWrapper,
      )}
    >
      <Tooltip content={tooltip}>
        <SelectPicker
          data={languagesOptions}
          value={languageId}
          onClean={onLanguageClean}
          onSelect={onLanguageSelect}
          disabled={isDisabled}
          cleanable={false}
          placement="autoVerticalStart"
          searchable
          caretAs={() => <ChevronDown />}
          {...props}
        />
      </Tooltip>
      {Boolean(error) && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
