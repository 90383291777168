import {
  createSlice,
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { selectUserId } from "../user/userSelector";
import { selectCompanyId } from "../company/companySelector";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports
import * as api from "./trackersCollectionsApi";

export const trackersCollectionsAdapter =
  createEntityAdapter<TrackersCollection.Data>({
    sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
  });

const initialState =
  trackersCollectionsAdapter.getInitialState<Store.InitialState>({
    status: "idle",
    error: null,
  });

export const fetchTrackersCollectionsByCompanyId = createAsyncThunk<
  TrackersCollection.Data[],
  Company.Data["id"]
>(
  "trackers-collections/fetch-by-company-id",
  api.getTrackersCollectionsByCompanyId,
);

export const fetchTrackersCollectionById = createAsyncThunk<
  TrackersCollection.Data,
  TrackersCollection.Data["id"]
>("trackers-collections/fetch-by-id", api.getTrackersCollectionById);

export const createTrackersCollection = createAsyncThunk<
  TrackersCollection.Data,
  Store.CreateEntity<Pick<TrackersCollection.Data, "name" | "trackerIds">>,
  { state: Store.RootState }
>("trackers-collections/create-one", async (payload, { getState }) => {
  const state = getState();
  const companyId = selectCompanyId(state);
  const userId = selectUserId(state);

  const _payload = {
    ...payload,
    authorId: userId,
    companyId,
    tags: [],
  };

  return api.createTrackersCollection(_payload);
});

export const updateTrackersCollection = createAsyncThunk<
  Store.UpdateEntity<TrackersCollection.Data>,
  Store.UpdateEntity<TrackersCollection.Data>
>("trackers-collections/update-one", api.updateTrackersCollection);

export const updateTrackersCollections = createAsyncThunk<
  Store.UpdateEntity<TrackersCollection.Data>[],
  Store.UpdateEntity<TrackersCollection.Data>[]
>("trackers-collections/update-many", api.updateTrackersCollections);

export const updateTrackersCollectionsByAuthorId = createAsyncThunk<
  Store.UpdateEntity<TrackersCollection.Data>[],
  {
    changes: Store.UpdateEntity<TrackersCollection.Data>["changes"];
    authorId: TrackersCollection.Data["authorId"];
  },
  { state: Store.RootState }
>("trackers-collections/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateTrackersCollectionsByAuthorId(payload, companyId);
});

export const removeTrackersCollection = createAsyncThunk<
  TrackersCollection.Data["id"],
  TrackersCollection.Data["id"]
>("trackers-collections/remove-one", async (payload) => {
  await api.deleteTrackersCollection(payload);

  return payload;
});

export const removeTrackersCollections = createAsyncThunk<
  TrackersCollection.Data["id"][],
  TrackersCollection.Data["id"][]
>("trackers-collections/remove-many", async (payload) => {
  await api.deleteTrackersCollections(payload);

  return payload;
});

const trackersSlice = createSlice({
  name: "trackers-collections",
  initialState,
  reducers: {
    addTrackersCollections: trackersCollectionsAdapter.addMany,
    updateSyncTrackersCollection(
      state,
      action: PayloadAction<Store.UpdateEntity<TrackersCollection.Data>>,
    ): void {
      const {
        payload: { id, changes },
      } = action;

      const existingTrackersCollection = state.entities[id];

      if (!existingTrackersCollection) return;

      state.entities = {
        ...state.entities,
        [id]: {
          ...existingTrackersCollection,
          ...changes,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchTrackersCollectionsByCompanyId.pending,
      onStatePending,
    );
    builder.addCase(
      fetchTrackersCollectionsByCompanyId.rejected,
      onStateRejected,
    );
    builder.addCase(
      fetchTrackersCollectionsByCompanyId.fulfilled,
      (...args) => {
        trackersCollectionsAdapter.addMany(...args);
        onStateFulfilled(...args);
      },
    );

    builder.addCase(
      fetchTrackersCollectionById.fulfilled,
      trackersCollectionsAdapter.addOne,
    );

    builder.addCase(
      createTrackersCollection.fulfilled,
      trackersCollectionsAdapter.addOne,
    );

    builder.addCase(
      updateTrackersCollection.fulfilled,
      trackersCollectionsAdapter.updateOne,
    );

    builder.addCase(
      updateTrackersCollections.fulfilled,
      trackersCollectionsAdapter.updateMany,
    );

    builder.addCase(
      updateTrackersCollectionsByAuthorId.fulfilled,
      trackersCollectionsAdapter.updateMany,
    );

    builder.addCase(
      removeTrackersCollection.fulfilled,
      trackersCollectionsAdapter.removeOne,
    );

    builder.addCase(
      removeTrackersCollections.fulfilled,
      trackersCollectionsAdapter.removeMany,
    );
  },
});

export default trackersSlice.reducer;

export const { updateSyncTrackersCollection, addTrackersCollections } =
  trackersSlice.actions;
