import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { GLOBAL_LANGUAGE_ID } from "src/constants";
import { selectLanguagesEntities } from "src/store/selectors";

type Result = {
  id: Location.Data["id"];
  name: string;
};

export const useTrackerLanguage = (
  searches: Array<Search.Data | Search.CreationData>,
): Result => {
  const { t } = useTranslation();

  const languages = useSelector(selectLanguagesEntities);

  return useMemo<Result>(() => {
    const languageIds = new Set<Language.Data["id"]>();

    for (const { languageId } of searches) {
      const language = languages[languageId];

      if (language) languageIds.add(languageId);
    }

    if (languageIds.size > 1)
      return {
        id: GLOBAL_LANGUAGE_ID,
        name: t("tracker.label.language_mixed"),
        preciseName: t("tracker.label.language_mixed"),
      };

    const languageId = [...languageIds][0];

    if (!languageId) return { id: "", name: "", preciseName: "" };

    const language = languages[languageId];

    if (!language) return { id: "", name: "", preciseName: "" };

    return { id: languageId, name: language.name };
  }, [languages, searches, t]);
};
