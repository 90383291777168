import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { selectCompanyId } from "src/store/selectors";
import { updateTrackerIdsWithPartialData } from "src/store/actions";
import { subscribeOnTrackersWithPartialData } from "src/store/trackers/trackersApi";

export const useTrackersWithPartialDataObserver = () => {
  const dispatch = useAppDispatch();

  const [isObserverSet, setIsObserverSet] = useState<boolean>(false);

  const companyId = useSelector(selectCompanyId);

  const callback = useCallback(
    (value: Record<Tracker.Data["id"], Search.Status>): void => {
      if (!value) return;

      dispatch(updateTrackerIdsWithPartialData(value));

      setIsObserverSet(true);
    },
    [dispatch],
  );

  useEffect(() => {
    if (!companyId) return;

    let observer = () => {};

    try {
      observer = subscribeOnTrackersWithPartialData(companyId, callback);
    } catch (err) {
      console.error(err);
    }

    return () => {
      observer();
    };
  }, [companyId, callback]);

  return { isObserverSet };
};
