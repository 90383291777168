import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE } from "src/constants";

// Inner imports
import { languagesAdapter } from "./languagesSlice";

export const {
  selectAll: selectLanguages,
  selectEntities: selectLanguagesEntities,
  selectById: selectLanguageById,
} = languagesAdapter.getSelectors<Store.RootState>(
  ({ languages }) => languages,
);

export const selectLanguagesStatus = createDraftSafeSelector(
  ({ languages }: Store.RootState) => languages,
  (languages) => languages.status,
);

export const selectLanguagesByKeywordsDataSource = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSource: Nullable<Search.KeywordsDataSource>,
  ): [
    ReturnType<typeof selectLanguages>,
    Nullable<Search.KeywordsDataSource>,
  ] => [selectLanguages(state), keywordsDataSource],
  ([languages, keywordsDataSource]) =>
    languages.filter(({ keywordsDataSources }) =>
      keywordsDataSources.includes(
        keywordsDataSource || SEARCH_DEFAULT_KEYWORDS_DATA_SOURCE,
      ),
    ),
);

export const selectLanguagesByKeywordsDataSources = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSources: Nullable<Search.KeywordsDataSource>[],
  ): [
    ReturnType<typeof selectLanguages>,
    Nullable<Search.KeywordsDataSource>[],
  ] => [selectLanguages(state), keywordsDataSources],
  ([languages, keywordsDataSources]) => {
    const filteredLanguages = new Set<Language.Data>();

    for (const language of languages) {
      const hasEveryDataSource = keywordsDataSources.every((dataSource) => {
        if (!dataSource) return true;

        return language.keywordsDataSources.includes(dataSource);
      });

      if (hasEveryDataSource) filteredLanguages.add(language);
    }

    return Array.from(filteredLanguages);
  },
);
