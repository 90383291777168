import { useMemo } from "react";
import { useSelector } from "react-redux";

import styles from "./DuplicateDashboardTracker.module.scss";
import { GREEN } from "src/constants";
import { Checkbox } from "src/components";
import { TrackerKeywordsDataSourcesIcon } from "src/features/";
import { useTrackerLanguage, useTrackerLocation } from "src/hooks";
import {
  selectTrackerById,
  selectSearchesByTrackerId,
} from "src/store/selectors";

type Props = {
  trackers: Tracker.Data[];
  trackerId: Tracker.Data["id"];
  selectedTrackerIds: Tracker.Data["id"][];
  toggleSelectTrackerIdHandler: (value: Tracker.Data["id"]) => void;
};

export const DuplicateDashboardTracker = ({
  trackers,
  trackerId,
  selectedTrackerIds,
  toggleSelectTrackerIdHandler,
}: Props) => {
  const tracker = useSelector((state: Store.RootState) =>
    selectTrackerById(state, trackerId),
  );

  const searches = useSelector((state: Store.RootState) =>
    selectSearchesByTrackerId(state, trackerId),
  );

  const { name: locationName } = useTrackerLocation(searches);

  const { name: languageName } = useTrackerLanguage(searches);

  const isSelected = useMemo<boolean>(
    () => selectedTrackerIds.includes(trackerId),
    [selectedTrackerIds, trackerId],
  );

  const isSelectDisabled = useMemo<boolean>(
    () => trackers.length <= 1,
    [trackers.length],
  );

  const toggleSelectTrackerId = (): void =>
    toggleSelectTrackerIdHandler(trackerId);

  if (!tracker) return null;

  return (
    <div className={styles.wrapper}>
      <Checkbox
        color={GREEN}
        isChecked={isSelected}
        isDisabled={isSelectDisabled}
        onClick={toggleSelectTrackerId}
      />
      <span className={styles.name}>{tracker.name}</span>
      <div className={styles.settings}>
        <TrackerKeywordsDataSourcesIcon trackerId={tracker.id} />
        <span className={styles.location}>{locationName}</span>
        <span className={styles.language}>{languageName}</span>
      </div>
    </div>
  );
};
