import { FC, useEffect, useMemo } from "react";
import { useImmer } from "use-immer";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./EditSearchModal.module.scss";
import { withError } from "src/hocs";
import { Alert, Form, Input, Label } from "src/components";
import { useElementFocus, useModal, useTemporaryErrors } from "src/hooks";
import { SEARCH_INPUT_LIMIT, SEARCH_NAME_INPUT_LIMIT } from "src/constants";
import {
  selectLanguagesByKeywordsDataSources,
  selectLocationsByKeywordsDataSources,
} from "src/store/selectors";
import {
  removeExtraSpaces,
  getDifferenceInObjects,
  getLocationDefaultLanguageId,
} from "src/utils";
import {
  ConfirmModal,
  LocationsDropdown,
  LanguagesDropdown,
} from "src/features";

const InputWithError = withError(Input);

type Props = {
  search: Search.Data;
  submitHandler: ({
    id,
    changes,
    callback,
  }: {
    id: Search.Data["id"];
    changes: Search.CreationData;
    callback: () => void;
  }) => void;
};

export const EditSearchModal: FC<Props> = ({
  submitHandler,
  search: defaultSearch,
}) => {
  const { t } = useTranslation();

  const [ref, setFocus] = useElementFocus();

  const { closeModal } = useModal();

  const { errors, setErrors } = useTemporaryErrors(3000);

  const [search, setSearch] = useImmer<Search.CreationData>(defaultSearch);

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSources(state, [search.keywordsDataSource]),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSources(state, [search.keywordsDataSource]),
  );

  const isSearchChanged = useMemo<boolean>(
    () => Boolean(getDifferenceInObjects(search, defaultSearch)),
    [search, defaultSearch],
  );

  const isDisabled = useMemo<boolean>(
    () => !isSearchChanged,
    [isSearchChanged],
  );

  useEffect(() => setFocus(), [setFocus]);

  const onSubjectChange = (value: string): void =>
    setSearch((draft) => {
      draft.subject = value;
    });

  const onDescriptionChange = (value: string): void =>
    setSearch((draft) => {
      draft.description = value;
    });

  const onLocationIdChange = (value: string): void =>
    setSearch((draft) => {
      if (value === search.locationId) return;

      const languageId = getLocationDefaultLanguageId(
        value,
        locations,
        languages,
      );

      draft.locationId = value;

      draft.languageId = languageId;
    });

  const onLanguageIdChange = (value: string): void =>
    setSearch((draft) => {
      draft.languageId = value;
    });

  const onSubmit = (): void => {
    const errors = validate();

    if (Object.keys(errors).length) return setErrors(errors);

    submitHandler({
      id: search.id,
      changes: {
        ...search,
        subject: removeExtraSpaces(search.subject),
        description: removeExtraSpaces(search.description || ""),
      },
      callback: () => closeModal("edit-search"),
    });
  };

  function validate() {
    const validationErrors: typeof errors = {};

    const { subject } = search;

    if (!subject.trim())
      validationErrors.subject = t(
        "component.modal.edit_search.form.validation.name_required",
      );

    return validationErrors;
  }

  return (
    <ConfirmModal
      id="edit-search"
      type="info"
      acceptButton={{
        text: t("component.modal.edit_search.button.submit"),
        onClick: onSubmit,
        disabled: isDisabled,
      }}
      cancelButton={{
        text: t("component.modal.edit_search.button.cancel"),
        onClick: () => closeModal("edit-search"),
      }}
      title={t("component.modal.edit_search.title", {
        name: defaultSearch.subject,
      })}
    >
      <div className={styles.wrapper}>
        <Form
          onSubmit={onSubmit}
          disabled={isDisabled}
          className={styles.formWrapper}
        >
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.edit_search.form.label.name")}
            />
            <InputWithError
              ref={ref}
              error={errors.subject}
              value={search.subject}
              changeHandler={onSubjectChange}
              characterLimit={SEARCH_NAME_INPUT_LIMIT}
              placeholder={t(
                "component.modal.edit_search.form.placeholder.name",
              )}
            />
          </div>
          <div className={styles.inputWrapper}>
            <Label
              leftText={t("component.modal.edit_search.form.label.description")}
            />
            <InputWithError
              error={errors.description}
              value={search.description}
              characterLimit={SEARCH_INPUT_LIMIT}
              changeHandler={onDescriptionChange}
              placeholder={t(
                "component.modal.edit_search.form.placeholder.description",
              )}
            />
          </div>
          {/*TODO: add when multiple keywords sources tracker is ready*/}
          {/*<div className={styles.inputWrapper}>*/}
          {/*<Label*/}
          {/*  leftText={t(*/}
          {/*    "component.modal.edit_search.form.label.keywords_data_source",*/}
          {/*  )}*/}
          {/*/>*/}
          {/*<KeywordsDataSourcesDropdown*/}
          {/*  hasDefaultStyles*/}
          {/*  openingDirection="bottom-end"*/}
          {/*  dataSource={search.keywordsDataSource}*/}
          {/*  setDataSource={onKeywordsDataSourceChange}*/}
          {/*/>*/}
          {/*</div>*/}
          <div className={styles.groupWrapper}>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t("component.modal.edit_search.form.label.location")}
              />
              <LocationsDropdown
                locationId={search.locationId}
                setLocationId={onLocationIdChange}
                hasDefaultStyles
                keywordsDataSources={[search.keywordsDataSource]}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Label
                leftText={t("component.modal.edit_search.form.label.language")}
              />
              <LanguagesDropdown
                hasDefaultStyles
                languageId={search.languageId}
                setLanguageId={onLanguageIdChange}
                keywordsDataSources={[search.keywordsDataSource]}
              />
            </div>
          </div>
        </Form>
        <Alert
          type="info"
          message={t(
            "component.modal.edit_search.status.warning.search_update",
          )}
        />
      </div>
    </ConfirmModal>
  );
};
