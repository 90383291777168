import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { usePreviousState } from "src/hooks";
import {
  selectLanguagesByKeywordsDataSources,
  selectLocationsByKeywordsDataSources,
} from "src/store/selectors";
import { getLocationDefaultLanguageId } from "../utils";

export const useLanguageId = ({
  locationId = null,
  keywordsDataSources = [],
  languageId: defaultLanguageId = null,
}: {
  locationId?: Nullish<Location.Data["id"]>;
  languageId?: Nullish<Language.Data["id"]>;
  keywordsDataSources?: Nullable<Search.KeywordsDataSource>[];
}): [
  Nullable<Location.Data["id"]>,
  Dispatch<SetStateAction<Nullable<Location.Data["id"]>>>,
] => {
  const formattedKeywordsDataSources = useMemo<
    Search.KeywordsDataSource[]
  >(() => {
    const dataSources = new Set<Search.KeywordsDataSource>();

    for (const dataSource of keywordsDataSources)
      if (dataSource) dataSources.add(dataSource);

    return Array.from(dataSources);
  }, [keywordsDataSources]);

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSources(state, formattedKeywordsDataSources),
  );

  const languages = useSelector((state: Store.RootState) =>
    selectLanguagesByKeywordsDataSources(state, formattedKeywordsDataSources),
  );

  const previousLocationId = usePreviousState(locationId);

  const [languageId, setLanguageId] =
    useState<Nullable<Language.Data["id"]>>(defaultLanguageId);

  useEffect(() => {
    if (languageId || !locationId) return;

    const newLanguageId = getLocationDefaultLanguageId(
      locationId,
      locations,
      languages,
    );

    setLanguageId(newLanguageId);
  }, [languageId, languages, locationId, locations]);

  useEffect(() => {
    if (!previousLocationId || !locationId || previousLocationId === locationId)
      return;

    const newLanguageId = getLocationDefaultLanguageId(
      locationId,
      locations,
      languages,
    );

    setLanguageId(newLanguageId);
  }, [languages, locationId, locations, previousLocationId]);

  useEffect(() => {
    if (defaultLanguageId) setLanguageId(defaultLanguageId);
  }, [defaultLanguageId]);

  useEffect(() => {
    if (!languageId) return;

    const isLanguageAvailable = languages.some(({ id }) => id === languageId);

    if (!isLanguageAvailable) setLanguageId(null);
  }, [languages, languageId]);

  return [languageId, setLanguageId];
};
