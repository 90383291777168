import { createDraftSafeSelector } from "@reduxjs/toolkit";

// Inner imports
import { locationsAdapter } from "./locationsSlice";

export const {
  selectAll: selectLocations,
  selectEntities: selectLocationsEntities,
  selectById: selectLocationById,
} = locationsAdapter.getSelectors<Store.RootState>(
  ({ locations }) => locations,
);

export const selectLocationsStatus = createDraftSafeSelector(
  ({ locations }: Store.RootState) => locations,
  (locations) => locations.status,
);

export const selectActiveLocations = createDraftSafeSelector(
  selectLocations,
  (locations) => locations.filter(({ isActive }) => isActive),
);

export const selectLocationsByKeywordsDataSources = createDraftSafeSelector(
  (
    state: Store.RootState,
    keywordsDataSources: Nullable<Search.KeywordsDataSource>[],
  ): [
    ReturnType<typeof selectActiveLocations>,
    Nullable<Search.KeywordsDataSource>[],
  ] => [selectActiveLocations(state), keywordsDataSources],
  ([locations, keywordsDataSources]) => {
    const filteredLocations = new Set<Location.Data>();

    for (const location of locations) {
      const hasEveryDataSource = keywordsDataSources.every((dataSource) => {
        if (!dataSource) return true;

        return location.keywordsDataSources.includes(dataSource);
      });

      if (hasEveryDataSource) filteredLocations.add(location);
    }

    return Array.from(filteredLocations);
  },
);
