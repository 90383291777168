import { ForwardedRef, forwardRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "./DuplicateDashboardConfiguration.module.scss";
import { Button } from "src/components";
import { getLocationName } from "src/utils";
import { selectLocationById, selectLanguageById } from "src/store/selectors";

type Props = {
  configuration: Tracker.DuplicateConfig;
  removeConfigurationHandler: (configuration: Tracker.DuplicateConfig) => void;
};

export const DuplicateDashboardConfiguration = forwardRef(
  (
    { configuration, removeConfigurationHandler }: Props,
    forwardedRef: ForwardedRef<HTMLDivElement>,
  ) => {
    const { t } = useTranslation();

    const location = useSelector((state: Store.RootState) =>
      selectLocationById(state, configuration.locationId),
    );

    const language = useSelector((state: Store.RootState) =>
      selectLanguageById(state, configuration.languageId),
    );

    const locationName = useMemo<string>(() => {
      if (!location) return "";

      return getLocationName(location);
    }, [location]);

    const languageName = useMemo<string>(() => {
      if (!language) return "";

      return language.name;
    }, [language]);

    return (
      <div className={styles.wrapper} ref={forwardedRef}>
        <div className={styles.location}>{locationName}</div>
        <div className={styles.language}>{languageName}</div>
        <Button
          onClick={() => removeConfigurationHandler(configuration)}
          className={styles.button}
          buttonSize="small"
          buttonStyle="transparent"
        >
          {t("component.modal.duplicate_dashboard.button.remove_configuration")}
        </Button>
      </div>
    );
  },
);
