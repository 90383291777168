import { FC, useMemo } from "react";
import cx from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SelectPicker, SelectPickerProps } from "rsuite";

import styles from "./DashboardVisibilityDropdown.module.scss";
import { Tooltip } from "src/components";
import { ChevronDown } from "src/assets/icons";
import { selectUser } from "src/store/selectors";
import { isDashboardVisibilityTypeGuard } from "src/utils";
import { DASHBOARD_VISIBILITY_OPTIONS } from "src/constants";

type Props = Omit<SelectPickerProps, "data"> & {
  error?: string;
  isDisabled?: boolean;
  hasDefaultStyles?: boolean;
  visibility: Nullable<Dashboard.Visibility>;
  setVisibility: (value: Nullable<Dashboard.Visibility>) => void;
};

export const DashboardVisibilityDropdown: FC<Props> = ({
  error,
  visibility,
  setVisibility,
  hasDefaultStyles = false,
  isDisabled: defaultIsDisabled = false,
  ...props
}) => {
  const { t } = useTranslation();

  const user = useSelector(selectUser);

  const visibilityOptions = useMemo<Option[]>(() => {
    const options: Option[] = [];

    for (const option of DASHBOARD_VISIBILITY_OPTIONS) {
      const { checkIsAvailable } = option;

      if (checkIsAvailable && !checkIsAvailable(user)) continue;

      options.push({ ...option, label: t(option.label) });
    }

    return options;
  }, [user, t]);

  const hasOneOption = useMemo<boolean>(
    () => visibilityOptions.length === 1,
    [visibilityOptions.length],
  );

  const isDisabled = useMemo<boolean>(
    () => defaultIsDisabled || hasOneOption,
    [defaultIsDisabled, hasOneOption],
  );

  const tooltip = useMemo<string>(() => {
    if (hasOneOption)
      return t("component.dashboard_visibility_dropdown.tooltip.single_option");

    return "";
  }, [hasOneOption, t]);

  const onVisibilitySelect = (value: string): void => {
    if (!isDashboardVisibilityTypeGuard(value)) return;

    setVisibility(value);
  };

  const onVisibilityClean = (): void => setVisibility(null);

  return (
    <div
      className={cx(
        styles.wrapper,
        hasDefaultStyles ? "" : styles.updatedWrapper,
      )}
    >
      <Tooltip content={tooltip}>
        <SelectPicker
          data={visibilityOptions}
          value={visibility}
          onClean={onVisibilityClean}
          onSelect={onVisibilitySelect}
          disabled={isDisabled}
          cleanable={false}
          placement="autoVerticalStart"
          searchable
          caretAs={() => <ChevronDown />}
          {...props}
        />
      </Tooltip>
      {Boolean(error) && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
