import firestore from "src/services/firestore";
import { showDevelopmentError } from "src/utils";
import { COLLECTION_IDS } from "../constants";
import languageSchema from "./languagesSchema";

export const getLanguages = async (): Promise<Language.Data[]> => {
  const res = await firestore().collection(COLLECTION_IDS.languages).get();

  return res.docs.reduce<Language.Data[]>((acc, doc) => {
    try {
      const language = languageSchema.validateSync(doc.data());

      acc.push({
        ...language,
        id: doc.id,
      });
    } catch (error) {
      const errorTitle = `LANGUAGE VALIDATION ERROR (${doc.id})`;

      showDevelopmentError({ error, additionalTexts: [errorTitle] });
    }

    return acc;
  }, []);
};
