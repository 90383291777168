import { useState, FormEvent, useMemo, FC } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Profile.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { selectUser } from "src/store/selectors";
import { Label, Input, GeneralFormButtons } from "src/components";
import { showToastNotification, triggerGtmEvent } from "src/utils";
import { updateCompanyMember, updateUser } from "src/store/actions";
import { useBlockRedirection, useTemporaryErrors } from "src/hooks";

// Inner imports
import { SETTINGS_PROFILE_INPUT_MAX_LENGTH } from "./constants";

const InputWithError = withError(Input);

const Profile: FC = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const { errors, setErrors } = useTemporaryErrors();

  const {
    id: userId,
    firstName: initialFirstName = "",
    lastName: initialLastName = "",
    role: initialRole = "",
    phone: initialPhone = "",
    department: initialDepartment = "",
    email,
  } = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [firstName, setFirstName] =
    useState<User.Data["firstName"]>(initialFirstName);

  const [lastName, setLastName] =
    useState<User.Data["lastName"]>(initialLastName);

  const [role, setRole] = useState<User.Data["role"]>(initialRole);

  const [phone, setPhone] = useState<User.Data["phone"]>(initialPhone);

  const [department, setDepartment] =
    useState<User.Data["department"]>(initialDepartment);

  const isFormDataChanged = useMemo<boolean>(() => {
    const isFirstNameChanged = initialFirstName !== firstName;
    const isLastNameChanged = initialLastName !== lastName;
    const isLastRoleChanged = initialRole !== role;
    const isLastPhoneChanged = initialPhone !== phone;
    const isLastDepartmentChanged = initialDepartment !== department;

    return (
      isFirstNameChanged ||
      isLastNameChanged ||
      isLastRoleChanged ||
      isLastPhoneChanged ||
      isLastDepartmentChanged
    );
  }, [
    department,
    firstName,
    initialDepartment,
    initialFirstName,
    initialLastName,
    initialPhone,
    initialRole,
    lastName,
    phone,
    role,
  ]);

  useBlockRedirection(history, isFormDataChanged);

  const onFormSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length || !isFormDataChanged) return;

    try {
      setIsLoading(true);

      await dispatch(
        updateUser({ firstName, lastName, role, phone, department }),
      ).unwrap();

      await dispatch(
        updateCompanyMember({
          id: userId,
          changes: { firstName, lastName },
        }),
      ).unwrap();

      triggerGtmEvent("UserUpdateIntercomData", {
        userId,
        userEmail: email,
        userPhone: phone,
        userName: `${firstName} ${lastName}`,
      });

      showToastNotification({
        text: t("page.settings.profile.status.success.user_updated"),
        type: "success",
      });
    } catch (error) {
      console.error(error);

      showToastNotification({
        text: t("common.error.server_error"),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  function validateForm(): Errors {
    const validationErrors: Errors = {};

    if (!firstName.trim())
      validationErrors.firstName = t(
        "page.settings.profile.form.validation.first_name_required",
      );

    if (!lastName.trim())
      validationErrors.lastName = t(
        "page.settings.profile.form.validation.last_name_required",
      );

    setErrors(validationErrors);

    return validationErrors;
  }

  return (
    <form className={styles.formWrapper} onSubmit={onFormSubmit}>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.first_name")}
          />
          <InputWithError
            className={styles.input}
            value={firstName}
            changeHandler={setFirstName}
            error={errors.firstName}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.last_name")}
          />
          <InputWithError
            className={styles.input}
            value={lastName}
            changeHandler={setLastName}
            error={errors.lastName}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.email")}
          />
          <InputWithError
            className={styles.input}
            value={email}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
            disabled
            readOnly
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.role")}
            isOptional
          />
          <Input
            className={styles.input}
            value={role}
            changeHandler={setRole}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.phone_number")}
            isOptional
          />
          <Input
            className={styles.input}
            value={phone}
            changeHandler={setPhone}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.profile.form.label.department")}
            isOptional
          />
          <Input
            className={styles.input}
            value={department}
            changeHandler={setDepartment}
            maxLength={SETTINGS_PROFILE_INPUT_MAX_LENGTH}
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <GeneralFormButtons
          isLoading={isLoading}
          isButtonsActive={isFormDataChanged}
          isCancelButtonHidden
        />
      </div>
    </form>
  );
};

export default Profile;
