import * as yup from "yup";

import { SEARCH_KEYWORDS_DATA_SOURCES, SEARCH_STATUSES } from "src/constants";

const trackerKeywordsDataSourcesSchema = yup
  .string<Search.KeywordsDataSource>()
  .oneOf([...SEARCH_KEYWORDS_DATA_SOURCES])
  .required();

export const trackerSchema = yup
  .object({
    authorId: yup.string().required(),
    companyId: yup.string().required(),

    searchIds: yup.array(yup.string().default("")).default([]),
    keywordsDataSources: yup.array(trackerKeywordsDataSourcesSchema).required(),

    category: yup.string().required(),
    description: yup.string().default(""),

    name: yup.string().default(""),
    tags: yup.array(yup.string().default("")).default([]),

    createdAt: yup.string().default(""),
    updatedAt: yup.string().default(""),
  })
  .noUnknown()
  .required();

const trackerSuggestedCategorySchema = yup
  .object({
    subject: yup.string().required(),
    category: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export const trackerSuggestedCategoriesSchema = yup
  .array(trackerSuggestedCategorySchema)
  .required();

const trackerSuggestedPerspectiveSchema = yup.string().required();

export const trackerSuggestedPerspectivesSchema = yup
  .array(trackerSuggestedPerspectiveSchema)
  .required();

const suggestedTrackerSchema = yup
  .object({
    subject: yup.string().required(),
    category: yup.string().required(),
    description: yup.string().required(),
  })
  .required();

export const suggestedTrackersSchema = yup
  .array(suggestedTrackerSchema)
  .required();

export const trackerWithPartialDataSchema = yup
  .object({
    status: yup
      .string<Search.Status>()
      .oneOf([...SEARCH_STATUSES])
      .required(),
  })
  .required();

export const duplicatedDashboardTrackersSchema = yup.object({
  trackerIds: yup.array(yup.string().required()).required(),
});

export const createTrackersWithSearchesSchema = yup.object({
  trackerIds: yup.array(yup.string().required()).required(),
});

export const trackerDescriptionSchema = yup.string().default("");

export const trackerKeywordsExportSchema = yup.object({
  name: yup.string().required(),
  data: yup
    .mixed<Blob>()
    .test((value: unknown) => value instanceof Blob)
    .required(),
});

export const duplicateTrackerSchema = yup.object({
  trackerIds: yup.array(yup.string().required()).required(),
});

export type TrackerCategoriesSchemaType = yup.InferType<
  typeof trackerSuggestedCategoriesSchema
>;

export type SuggestedTrackersSchemaType = yup.InferType<
  typeof suggestedTrackersSchema
>;

export type CreateTrackersWithSearchesSchemaType = yup.InferType<
  typeof createTrackersWithSearchesSchema
>;
