import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { useAppDispatch } from "src/store";
import { fetchSearchesByIds } from "src/store/actions";
import {
  selectSearchesByIds,
  selectTrackersCollectionSearchIds,
} from "src/store/selectors";

type Props = {
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const useDashboardSearchesLoader = ({ trackersCollectionId }: Props) => {
  const dispatch = useAppDispatch();

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>("idle");

  const searchIds = useSelector((state: Store.RootState) =>
    selectTrackersCollectionSearchIds(state, trackersCollectionId),
  );

  const searches = useSelector((state: Store.RootState) =>
    selectSearchesByIds(state, searchIds),
  );

  const isLoaded = useMemo<boolean>(
    () => Boolean(searches.length),
    [searches.length],
  );

  useEffect(() => {
    if (isLoaded) setLoadingStatus("succeeded");
    else setLoadingStatus("idle");
  }, [isLoaded]);

  useEffect(() => {
    if (!trackersCollectionId || loadingStatus !== "idle" || searches.length)
      return;

    setLoadingStatus("loading");

    dispatch(fetchSearchesByIds(searchIds))
      .unwrap()
      .then(() => {
        setLoadingStatus("succeeded");
      })
      .catch((error) => {
        console.error(error);

        setLoadingStatus("failed");
      });
  }, [
    dispatch,
    searchIds,
    loadingStatus,
    searches.length,
    trackersCollectionId,
  ]);

  return { loadingStatus };
};
