import TagManager from "react-gtm-module";

import firebaseAuth from "src/services/firebaseAuth";
import { GOOGLE_TAG_MANAGER_EVENTS } from "src/constants";
import { SearchLinkType } from "src/features/KeywordsTable/components/SearchLinkCell/types";

type DataLayer = {
  query: string;
  userName: string;
  isVersion4: boolean;
  userId: User.Data["id"];
  widgetId: Widget.IdType;
  eventId: Event.Data["id"];
  searchId: Search.Data["id"];
  companyMembersCount: number;
  trackerId: Tracker.Data["id"];
  companyId: Company.Data["id"];
  userEmail: User.Data["email"];
  userPhone: User.Data["phone"];
  searchLinkType: SearchLinkType;
  companyName: Company.Data["name"];
  dashboardId: Dashboard.Data["id"];
  whiteLabelId: WhiteLabel.Data["id"];
  userCreatedAt: User.Data["createdAt"];
  companyCreatedAt: Company.Data["createdAt"];
  subscriptionPlanId: SubscriptionPlan.Data["id"];
  subscriptionPlanName: SubscriptionPlan.Data["name"];
  trackersCollectionId: TrackersCollection.Data["id"];
};

export const triggerGtmEvent = (
  event: (typeof GOOGLE_TAG_MANAGER_EVENTS)[number],
  dataLayerObject: Partial<DataLayer> = {},
): void => {
  const currentUserId = firebaseAuth().currentUser?.uid;

  const userId = dataLayerObject.userId || currentUserId;

  TagManager.dataLayer({ dataLayer: { event, userId, ...dataLayerObject } });
};
