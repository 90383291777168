import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import omit from "lodash/omit";

import { DASHBOARD_DATE_RANGE_TYPES } from "src/constants";
import { addTrackers } from "src/store/trackers/trackersSlice";
import { selectUserId, selectCompanyId } from "src/store/selectors";
import { addTrackersCollections } from "../trackersCollections/trackersCollectionsSlice";
import { createDashboardDefaultDateRanges } from "src/store/dashboardDateRanges/dashboardDateRangesSlice";
import { onStateFulfilled, onStatePending, onStateRejected } from "../utils";

// Inner imports;
import * as api from "./dashboardsApi";
import { createDashboardPayload } from "./utils";

export const dashboardsAdapter = createEntityAdapter<Dashboard.Data>({
  sortComparer: (a, b) => a.createdAt.localeCompare(b.createdAt),
});

const initialState = dashboardsAdapter.getInitialState<Store.InitialState>({
  status: "idle",
  error: null,
});

export const fetchAllDashboards = createAsyncThunk<
  Dashboard.Data[],
  Company.Data["id"],
  { state: Store.RootState }
>("dashboards/fetch-all-by-company-id", api.getAllDashboards);

export const fetchDashboardById = createAsyncThunk<
  Dashboard.Data,
  Dashboard.Data["id"],
  { state: Store.RootState }
>("dashboards/fetch-by-id", api.getDashboardById);

export const createDashboard = createAsyncThunk<
  Dashboard.Data,
  Dashboard.DataForCreation,
  { state: Store.RootState }
>("dashboards/create-one", async (payload, { getState, dispatch }) => {
  const state = getState();

  const dashboardPayload = createDashboardPayload(state, payload);

  const result = await api.createDashboard(dashboardPayload);

  await dispatch(
    createDashboardDefaultDateRanges({
      authorId: dashboardPayload.authorId,
      companyId: dashboardPayload.companyId,
      types: Array.from(DASHBOARD_DATE_RANGE_TYPES),
      trackersCollectionId: result.trackersCollectionId,
    }),
  );

  return result;
});

export const updateDashboard = createAsyncThunk<
  Store.UpdateEntity<Dashboard.Data>,
  Store.UpdateEntity<Dashboard.Data>
>("dashboards/update-one", (payload) => {
  const formattedChanges = omit(payload.changes, "expandedWidgetId");

  const formattedPayload = { ...payload, changes: formattedChanges };

  return api.updateDashboard(formattedPayload);
});

export const updateDashboards = createAsyncThunk<
  Store.UpdateEntity<Dashboard.Data>[],
  Store.UpdateEntity<Dashboard.Data>[]
>("dashboards/update-many", api.updateDashboards);

export const updateDashboardsByAuthorId = createAsyncThunk<
  Store.UpdateEntity<Dashboard.Data>[],
  {
    changes: Store.UpdateEntity<Dashboard.Data>["changes"];
    authorId: Dashboard.Data["authorId"];
  },
  { state: Store.RootState }
>("dashboards/update-by-author-id", (payload, { getState }) => {
  const state = getState();

  const companyId = selectCompanyId(state);

  return api.updateDashboardsByAuthorId(payload, companyId);
});

export const removeDashboard = createAsyncThunk<
  Dashboard.Data["id"],
  Dashboard.Data["id"]
>("dashboards/remove-one", async (payload) => {
  await api.deleteDashboard(payload);

  return payload;
});

export const removeDashboards = createAsyncThunk<
  Dashboard.Data["id"][],
  Dashboard.Data["id"][]
>("dashboards/remove-many", async (payload) => {
  await api.deleteDashboards(payload);

  return payload;
});

export const duplicateDashboard = createAsyncThunk<
  Dashboard.Data[],
  {
    trackerIds: Tracker.Data["id"][];
    visibility: Dashboard.Visibility;
    dashboardId: Dashboard.Data["id"];
    configurations: Dashboard.DuplicateConfig[];
    shouldCopyKeywords: boolean;
  },
  { state: Store.RootState; dispatch: Store.AppDispatch }
>("dashboards/duplicate-dashboard", async (payload, { getState, dispatch }) => {
  const state = getState();

  const [companyId, userId] = [selectCompanyId(state), selectUserId(state)];

  const { dashboards, trackers, trackersCollections } =
    await api.duplicateDashboard({ ...payload, userId, companyId });

  dispatch(addTrackers(trackers));
  dispatch(addTrackersCollections(trackersCollections));

  return dashboards;
});

const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllDashboards.pending, onStatePending);
    builder.addCase(fetchAllDashboards.rejected, onStateRejected);
    builder.addCase(fetchAllDashboards.fulfilled, (...args) => {
      dashboardsAdapter.addMany(...args);
      onStateFulfilled(...args);
    });

    builder.addCase(fetchDashboardById.fulfilled, dashboardsAdapter.addOne);

    builder.addCase(createDashboard.fulfilled, dashboardsAdapter.addOne);

    builder.addCase(updateDashboard.fulfilled, dashboardsAdapter.updateOne);

    builder.addCase(updateDashboards.fulfilled, dashboardsAdapter.updateMany);

    builder.addCase(
      updateDashboardsByAuthorId.fulfilled,
      dashboardsAdapter.updateMany,
    );

    builder.addCase(removeDashboard.fulfilled, dashboardsAdapter.removeOne);

    builder.addCase(removeDashboards.fulfilled, dashboardsAdapter.removeMany);

    builder.addCase(duplicateDashboard.fulfilled, dashboardsAdapter.addMany);
  },
});

export default dashboardsSlice.reducer;
