import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTES } from "src/constants";
import { Translation } from "src/components";
import { showToastNotification } from "src/utils";
import {
  selectUserPermissions,
  selectDashboardsLimit,
  selectCompanyOwnerEmail,
  selectHasDashboardAccess,
} from "src/store/selectors";

export const useDashboardBlocker = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const { isDashboardsLimitExceeded, dashboardsLimit } = useSelector(
    selectDashboardsLimit,
  );

  const { hasDashboardAccess } = useSelector(selectHasDashboardAccess);

  const companyOwnerEmail = useSelector(selectCompanyOwnerEmail);

  const { isUserCompanyOwnerOrAdmin } = useSelector(selectUserPermissions);

  const isTrackerBlocked = useMemo<boolean>(
    () => !hasDashboardAccess || isDashboardsLimitExceeded,
    [hasDashboardAccess, isDashboardsLimitExceeded],
  );

  const showContactCompanyOwnerNotification = (): void => {
    const localizationKey = companyOwnerEmail
      ? "component.header.status.info.contact_company_owner"
      : "component.header.status.info.contact_support";

    return showToastNotification({
      type: "info",
      id: "subscription-plan-update",
      text: (
        <Translation
          i18nKey={localizationKey}
          values={{ email: companyOwnerEmail }}
        />
      ),
    });
  };

  const showDashboardLimitNotification = (): void => {
    if (!isUserCompanyOwnerOrAdmin)
      return showContactCompanyOwnerNotification();

    return showToastNotification({
      id: "dashboard-limit",
      type: "warning",
      closeButton: {
        buttonType: "success",
        text: t("dashboard.status.warning.button.upgrade"),
        onClick: () => history.push(`${ROUTES.settingsBillingPage}`),
      },
      text: (
        <Translation
          values={{ count: dashboardsLimit }}
          i18nKey="dashboard.status.warning.dashboard_limit"
        />
      ),
    });
  };

  const showSubscriptionPlanLimitNotification = (): void => {
    if (!isUserCompanyOwnerOrAdmin)
      return showContactCompanyOwnerNotification();

    return showToastNotification({
      type: "warning",
      id: "subscription-plan-limit",
      closeButton: {
        buttonType: "success",
        text: t("dashboard.status.warning.button.upgrade"),
        onClick: () => history.push(`${ROUTES.settingsBillingPage}`),
      },
      text: (
        <Translation i18nKey="dashboard.status.warning.dashboard_unavailable" />
      ),
    });
  };

  const showDashboardBlockerNotification = (): void => {
    if (!hasDashboardAccess) return showSubscriptionPlanLimitNotification();

    if (isDashboardsLimitExceeded) return showDashboardLimitNotification();
  };

  return {
    isTrackerBlocked,
    hasDashboardAccess,
    isDashboardsLimitExceeded,
    showDashboardLimitNotification,
    showDashboardBlockerNotification,
    showSubscriptionPlanLimitNotification,
  };
};
