import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { updateUserLastActiveAt } from "src/store/user/userApi";
import { showDevelopmentError, triggerGtmEvent } from "src/utils";
import {
  selectUser,
  selectUserId,
  selectCompany,
  selectCompanySubscriptionPlan,
} from "src/store/selectors";

export const useUpdateUserLastActiveAt = () => {
  const userId = useSelector(selectUserId);

  useEffect(() => {
    if (!userId) return;

    updateUserLastActiveAt(userId).catch((error) =>
      showDevelopmentError({
        error,
        additionalTexts: ["Error in updateUserLastActiveAt"],
      }),
    );
  }, [userId]);
};

export const useUserBootIntercom = (): void => {
  const user = useSelector(selectUser);

  const company = useSelector(selectCompany);

  const subscriptionPlan = useSelector(selectCompanySubscriptionPlan);

  const {
    email,
    phone,
    lastName,
    firstName,
    createdAt,
    id: userId,
  } = useMemo<User.Data>(() => user, [user]);

  const {
    whiteLabel,
    id: companyId,
    subscriptionPlanId,
  } = useMemo<Company.Data>(() => company, [company]);

  const isDataLoaded = useMemo<boolean>(
    () => Boolean(userId && companyId && subscriptionPlan?.id),
    [companyId, subscriptionPlan?.id, userId],
  );

  useEffect(() => {
    if (!isDataLoaded) return;

    triggerGtmEvent("UserBootIntercomData", {
      userId,
      companyId,
      userEmail: email,
      userPhone: phone,
      isVersion4: true,
      subscriptionPlanId,
      whiteLabelId: whiteLabel,
      userCreatedAt: createdAt,
      companyName: company.name,
      userName: `${firstName} ${lastName}`,
      companyCreatedAt: company.createdAt,
      subscriptionPlanName: subscriptionPlan?.name || "",
      companyMembersCount: Object.keys(company.members).length,
    });
  }, [isDataLoaded]); /* eslint-disable-line react-hooks/exhaustive-deps */
};
