import { FC, FormEvent, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import styles from "./Company.module.scss";
import { withError } from "src/hocs";
import { useAppDispatch } from "src/store";
import { updateCompany } from "src/store/actions";
import { LOCATION_COUNTRIES } from "src/constants";
import { showToastNotification, triggerGtmEvent } from "src/utils";
import { useBlockRedirection, useTemporaryErrors } from "src/hooks";
import { GeneralFormButtons, Input, Label, Select } from "src/components";
import {
  selectUser,
  selectCompany,
  selectUserPermissions,
} from "src/store/selectors";

// Inner imports
import { COMPANY_INPUT_MAX_LENGTH } from "./constants";

const InputWithError = withError(Input);
const SelectWithError = withError(Select);

const Company: FC = () => {
  const { t } = useTranslation();

  const history = useHistory();

  const dispatch = useAppDispatch();

  const { errors, setErrors } = useTemporaryErrors();

  const { isUserCompanyOwnerOrAdmin } = useSelector(selectUserPermissions);

  const {
    id: companyId,
    name: initialCompanyName = "",
    countryCode: initialCountryCode = "",
  } = useSelector(selectCompany);

  const { id: userId } = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [companyName, setCompanyName] =
    useState<Company.Data["name"]>(initialCompanyName);
  const [countryCode, setCountryCode] =
    useState<Company.Data["countryCode"]>(initialCountryCode);

  const isFormDataChanged = useMemo<boolean>(() => {
    const isCompanyNameChanged = companyName !== initialCompanyName;
    const isLocationIdChanged = countryCode !== initialCountryCode;

    return isCompanyNameChanged || isLocationIdChanged;
  }, [companyName, countryCode, initialCompanyName, initialCountryCode]);

  const onFormSubmit = async (e: FormEvent): Promise<void> => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length || !isFormDataChanged) return;

    try {
      setIsLoading(true);

      await dispatch(
        updateCompany({ countryCode, name: companyName }),
      ).unwrap();

      triggerGtmEvent("UserUpdateIntercomData", {
        userId,
        companyId,
        companyName,
      });

      showToastNotification({
        text: t("page.settings.company.status.success.company_updated"),
        type: "success",
      });
    } catch (error) {
      showToastNotification({
        text: t("common.error.server_error"),
        type: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // BLOCK REDIRECT IF DATA CHANGED --->
  useBlockRedirection(history, isFormDataChanged);
  // <--- BLOCK REDIRECT IF DATA CHANGED

  function validateForm(): Errors {
    const validationErrors: Errors = {};

    if (!companyName.trim())
      validationErrors.name = t(
        "page.settings.company.form.validation.name_required",
      );

    if (!countryCode)
      validationErrors.country = t(
        "page.settings.company.form.validation.country_required",
      );

    setErrors(validationErrors);

    return validationErrors;
  }

  return (
    <form className={styles.formWrapper} onSubmit={onFormSubmit}>
      <div className={styles.inputsWrapper}>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.company.form.label.name")}
          />
          <InputWithError
            className={styles.input}
            value={companyName}
            changeHandler={setCompanyName}
            disabled={!isUserCompanyOwnerOrAdmin}
            maxLength={COMPANY_INPUT_MAX_LENGTH}
            error={errors.name}
          />
        </div>
        <div className={styles.inputWrapper}>
          <Label
            className={styles.label}
            leftText={t("page.settings.company.form.label.country")}
          />
          <SelectWithError
            options={LOCATION_COUNTRIES}
            value={countryCode}
            changeHandler={setCountryCode}
            isDisabled={!isUserCompanyOwnerOrAdmin}
            error={errors.country}
            openingDirection="bottom-end"
          />
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <GeneralFormButtons
          isLoading={isLoading}
          isButtonsActive={isFormDataChanged}
          isCancelButtonHidden
        />
      </div>
    </form>
  );
};

export default Company;
