import { FC, useMemo } from "react";
import cx from "classnames";
import isString from "lodash/isString";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { TreePicker, TreePickerProps } from "rsuite";
import { TreeNode } from "rsuite/cjs/internals/Tree/types";

import styles from "./LocationsDropdown.module.scss";
import { Tooltip } from "src/components";
import { ChevronDown } from "src/assets/icons";
import { selectLocationsByKeywordsDataSources } from "src/store/selectors";

// Inner imports
import { createLocationTree } from "./utils";

type Props = Omit<TreePickerProps, "data"> & {
  error?: string;
  locationId: Nullable<Location.Data["id"]>;
  isDisabled?: boolean;
  setLocationId: (value: Location.Data["id"]) => void;
  hasDefaultStyles?: boolean;
  keywordsDataSources: Nullable<Search.KeywordsDataSource>[];
};

export const LocationsDropdown: FC<Props> = ({
  error,
  locationId,
  placeholder,
  setLocationId,
  keywordsDataSources,
  hasDefaultStyles = false,
  placement = "autoVerticalStart",
  isDisabled: defaultIsDisabled = false,
  ...props
}) => {
  const { t } = useTranslation();

  const locations = useSelector((state: Store.RootState) =>
    selectLocationsByKeywordsDataSources(state, keywordsDataSources),
  );

  const locationsOptions = useMemo<Option<Location.Data["id"]>[]>(
    () =>
      locations.map((location) => ({
        value: location.id,
        label: location.name,
      })),
    [locations],
  );

  const hasOneOption = useMemo<boolean>(
    () => locationsOptions.length === 1,
    [locationsOptions.length],
  );

  const isDisabled = useMemo<boolean>(() => {
    if (defaultIsDisabled) return defaultIsDisabled;

    return Boolean(locationId) && hasOneOption;
  }, [defaultIsDisabled, hasOneOption, locationId]);

  const tooltip = useMemo<string>(() => {
    if (hasOneOption && isDisabled)
      return t("component.locations_dropdown.tooltip.one_option.single_option");

    return "";
  }, [hasOneOption, t, isDisabled]);

  const onLocationSelect = (activeNode: TreeNode): void => {
    if (!activeNode) return;

    const { value } = activeNode;

    if (isString(value)) setLocationId(value);
  };

  const onLocationClean = (): void => setLocationId("");

  const locationTree = useMemo<TreeNode[]>(
    () => createLocationTree(locations),
    [locations],
  );

  return (
    <div
      className={cx(
        styles.wrapper,
        hasDefaultStyles ? "" : styles.updatedWrapper,
      )}
    >
      <Tooltip content={tooltip}>
        <TreePicker
          data={locationTree}
          value={locationId}
          onClean={onLocationClean}
          onSelect={onLocationSelect}
          disabled={isDisabled}
          placement={placement}
          cleanable={false}
          searchable
          defaultExpandAll={false}
          placeholder={placeholder}
          caretAs={() => <ChevronDown />}
          {...props}
        />
      </Tooltip>
      {Boolean(error) && (
        <div className={styles.error}>
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};
