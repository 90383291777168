import { ForwardedRef, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import styles from "../WidgetTile.module.scss";
import { useGlobalPreloader } from "src/hooks";
import { WIDGET_EXPORT_NAME } from "src/constants";
import {
  selectWidgetData,
  selectDashboardById,
  selectDashboardDateRangeById,
} from "src/store/selectors";
import {
  triggerGtmEvent,
  downloadHTMLImage,
  showToastNotification,
  DownloadHTMLImageFormat,
  customRequestIdleCallback,
} from "src/utils";

// Inner imports
import { WIDGET_EXPORT_IMAGE } from "../constants";

type Props = {
  view: Nullable<Widget.ChartView>;
  widgetId: Widget.IdType;
  ref: ForwardedRef<HTMLElement>;
  isExpanded: boolean;
  trackersCollectionId: TrackersCollection.Data["id"];
  dashboardDateRangeId: DashboardDateRange.Data["id"];
};

export const useExportWidgetImage = ({
  ref,
  view,
  widgetId,
  isExpanded,
  trackersCollectionId,
  dashboardDateRangeId,
}: Props) => {
  const { t } = useTranslation();

  const { showGlobalPreloader, hideGlobalPreloader } = useGlobalPreloader();

  const dashboard = useSelector((state: Store.RootState) =>
    selectDashboardById(state, trackersCollectionId),
  );

  const dashboardDateRange = useSelector((state: Store.RootState) =>
    selectDashboardDateRangeById(state, dashboardDateRangeId),
  );

  const widgetData = useSelector((state: Store.RootState) =>
    selectWidgetData(state, dashboardDateRangeId, widgetId),
  );

  const hasWidgetImageExport = useMemo<boolean>(
    () =>
      Boolean(dashboard && dashboardDateRange) &&
      WIDGET_EXPORT_IMAGE.includes(widgetId),
    [dashboard, dashboardDateRange, widgetId],
  );

  const [startDate, endDate] = useMemo<[ISODateString, ISODateString]>(() => {
    if (!widgetData) return ["", ""];

    return [widgetData.startDate, widgetData.endDate];
  }, [widgetData]);

  const exportWidgetImage = async (
    format: DownloadHTMLImageFormat,
  ): Promise<void> => {
    const element = ref && "current" in ref ? ref.current : null;

    if (!dashboard || !dashboardDateRange || !element || !view) return;

    const imageName = generateExportWidgetImageName({
      endDate,
      widgetId,
      dashboard,
      startDate,
    });

    showGlobalPreloader();

    const removeSnapshotStyles = applySnapshotStyles(view, isExpanded, element);

    customRequestIdleCallback(
      () =>
        downloadHTMLImage({ format, name: imageName, element })
          .then(() => {
            triggerGtmEvent("WidgetDownloadImage", {
              widgetId,
              dashboardId: trackersCollectionId,
            });

            hideGlobalPreloader();
          })
          .catch((error) => {
            console.error(error);

            showToastNotification({
              type: "error",
              text: t("common.error.server_error"),
            });
          })
          .finally(() => {
            removeSnapshotStyles();

            hideGlobalPreloader();
          }),
      100,
    );
  };

  return { hasWidgetImageExport, exportWidgetImage };
};

function applySnapshotStyles(
  view: Widget.ChartView,
  isExpanded: boolean,
  element: HTMLElement,
): () => void {
  let snapshotClassName: Nullable<string> = null;

  switch (view.type) {
    case "lineChart": {
      if (isExpanded && styles.snapshotLineChartExpanded)
        snapshotClassName = styles.snapshotLineChartExpanded;

      if (!isExpanded && styles.snapshotLineChart)
        snapshotClassName = styles.snapshotLineChart;

      break;
    }
    case "pieChart": {
      if (isExpanded && styles.snapshotPieChartExpanded)
        snapshotClassName = styles.snapshotPieChartExpanded;

      if (!isExpanded && styles.snapshotPieChart)
        snapshotClassName = styles.snapshotPieChart;

      break;
    }
    case "table": {
      if (isExpanded && styles.snapshotTableExpanded)
        snapshotClassName = styles.snapshotTableExpanded;

      if (!isExpanded && styles.snapshotTable)
        snapshotClassName = styles.snapshotTable;

      break;
    }
    case "keywords": {
      if (isExpanded && styles.snapshotTagCloudExpanded)
        snapshotClassName = styles.snapshotTagCloudExpanded;

      if (!isExpanded && styles.snapshotTagCloud)
        snapshotClassName = styles.snapshotTagCloud;

      break;
    }
  }

  if (snapshotClassName) element.classList.add(snapshotClassName);

  return () => {
    if (snapshotClassName) element.classList.remove(snapshotClassName);

    return;
  };
}

function generateExportWidgetImageName({
  endDate,
  widgetId,
  startDate,
  dashboard,
}: {
  endDate: ISODateString;
  widgetId: Widget.IdType;
  startDate: ISODateString;
  dashboard: Dashboard.Data;
}): string {
  const fileName: string[] = [];

  const dashboardName = dashboard.name;

  if (dashboardName) fileName.push(dashboardName);

  const widgetExportName = WIDGET_EXPORT_NAME[widgetId];

  if (widgetExportName) fileName.push(`[${widgetExportName}]`);

  if (startDate && endDate) fileName.push(`[${startDate} ${endDate}]`);

  const currentDate = new Date().toISOString();

  fileName.push(currentDate);

  return fileName.join(" ");
}
