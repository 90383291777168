import { createDraftSafeSelector } from "@reduxjs/toolkit";

import { isWidgetIdTypeGuard } from "src/utils";
import { selectCompanyEvents } from "../events/eventsSelector";
import { selectCompanyTrackers } from "../trackers/trackersSelector";
import { selectCompanyDashboards } from "../dashboards/dashboardsSelector";
import { selectCompany, selectCompanyById } from "../company/companySelector";

// Inner imports
import { subscriptionPlansAdapter } from "./subscriptionPlansSlice";

export const {
  selectAll: selectSubscriptionPlans,
  selectEntities: selectSubscriptionPlansEntities,
  selectById: selectSubscriptionPlanById,
  selectIds: selectSubscriptionPlanIds,
} = subscriptionPlansAdapter.getSelectors<Store.RootState>(
  ({ subscriptionPlans }) => subscriptionPlans,
);

export const selectSubscriptionPlansStatus = createDraftSafeSelector(
  ({ subscriptionPlans }: Store.RootState) => subscriptionPlans,
  (subscriptionPlans) => subscriptionPlans.status,
);

export const selectCompanySubscriptionPlanId = createDraftSafeSelector(
  selectCompany,
  (company) => company.subscriptionPlanId,
);

export const selectCompanySubscriptionPlan = createDraftSafeSelector(
  selectSubscriptionPlansEntities,
  selectCompanySubscriptionPlanId,
  (subscriptionPlans, companySubscriptionPlanId) =>
    subscriptionPlans[companySubscriptionPlanId],
);

export const selectSubscriptionPlanByCompanyId = createDraftSafeSelector(
  (
    state: Store.RootState,
    companyId: Company.Data["id"],
  ): [
    ReturnType<typeof selectSubscriptionPlansEntities>,
    ReturnType<typeof selectCompanyById>,
  ] => [
    selectSubscriptionPlansEntities(state),
    selectCompanyById(state, companyId),
  ],
  ([subPlans, company]) => {
    if (!company) return;

    for (const subPlanId in subPlans) {
      const subPlan = subPlans[subPlanId];

      if (!subPlan) continue;

      if (subPlanId === company.subscriptionPlanId) return subPlan;
    }
  },
);

export const selectSubscriptionPlanAvailableWidgetIds = createDraftSafeSelector(
  selectCompanySubscriptionPlan,
  (companySubPlan) => {
    const widgetIds = new Set<Widget.IdType>();

    if (!companySubPlan) return Array.from(widgetIds);

    for (const widgetId in companySubPlan.availableWidgetsMap) {
      if (!isWidgetIdTypeGuard(widgetId)) continue;

      if (companySubPlan.availableWidgetsMap[widgetId]) widgetIds.add(widgetId);
    }

    return Array.from(widgetIds);
  },
);

export const selectAvailableWidgetIdsByCompanyId = createDraftSafeSelector(
  (
    state: Store.RootState,
    companyId: Company.Data["id"],
  ): ReturnType<typeof selectSubscriptionPlanByCompanyId> =>
    selectSubscriptionPlanByCompanyId(state, companyId),
  (subPlan) => {
    const widgetIds = new Set<Widget.IdType>();

    if (!subPlan) return widgetIds;

    for (const widgetId in subPlan.availableWidgetsMap) {
      if (!isWidgetIdTypeGuard(widgetId)) continue;

      if (subPlan.availableWidgetsMap[widgetId]) widgetIds.add(widgetId);
    }

    return widgetIds;
  },
);

export const selectCompanyTeamLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [
    ReturnType<typeof selectCompany>,
    ReturnType<typeof selectCompanySubscriptionPlan>,
  ] => [selectCompany(state), selectCompanySubscriptionPlan(state)],
  ([company, subscriptionPlan]) => {
    const [teamLimit, adminsLimit] = [
      subscriptionPlan?.limits.team || 0,
      subscriptionPlan?.limits.admins || 0,
    ];

    const members = Object.values(company.members);

    const admins = members.filter(({ companyRole }) => companyRole === "admin");

    const [isTeamLimitExceeded, isAdminsLimitExceeded] = [
      members.length >= teamLimit,
      admins.length >= adminsLimit,
    ];

    return {
      teamLimit,
      adminsLimit,
      isTeamLimitExceeded,
      isAdminsLimitExceeded,
    };
  },
);

export const selectDashboardsLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [
    ReturnType<typeof selectCompanyDashboards>,
    ReturnType<typeof selectCompanySubscriptionPlan>,
  ] => [selectCompanyDashboards(state), selectCompanySubscriptionPlan(state)],
  ([dashboards, subscriptionPlan]) => {
    const dashboardsLimit = subscriptionPlan?.limits.dashboards || 0;

    const isDashboardsLimitExceeded = dashboards.length >= dashboardsLimit;

    return {
      dashboardsLimit,
      isDashboardsLimitExceeded,
      dashboardsCount: dashboards.length,
    };
  },
);

export const selectTrackersLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [
    ReturnType<typeof selectCompany>,
    ReturnType<typeof selectCompanyTrackers>,
    ReturnType<typeof selectCompanySubscriptionPlan>,
  ] => [
    selectCompany(state),
    selectCompanyTrackers(state),
    selectCompanySubscriptionPlan(state),
  ],
  ([company, trackers, subscriptionPlan]) => {
    const trackersLimit =
      (company.customTrackersLimit ?? subscriptionPlan?.limits.trackers) || 0;

    const isTrackersLimitExceeded = trackers.length >= trackersLimit;

    return {
      trackersLimit,
      isTrackersLimitExceeded,
      trackersCount: trackers.length,
    };
  },
);

export const selectTrackerSearchesLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    const trackerSearchesLimit = subscriptionPlan?.limits.trackerSearches || 0;

    return { trackerSearchesLimit };
  },
);

export const selectTrackersCollectionsLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    const trackersCollectionsLimit =
      subscriptionPlan?.limits.trackersCollections || 0;

    return { trackersCollectionsLimit };
  },
);

export const selectTrackersCollectionsTrackersLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    const trackersCollectionsTrackersLimit =
      subscriptionPlan?.limits.trackersCollectionTrackers || 0;

    return { trackersCollectionsTrackersLimit };
  },
);

export const selectEventsLimit = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [
    ReturnType<typeof selectCompanyEvents>,
    ReturnType<typeof selectCompanySubscriptionPlan>,
  ] => [selectCompanyEvents(state), selectCompanySubscriptionPlan(state)],
  ([events, subscriptionPlan]) => {
    const eventsLimit = subscriptionPlan?.limits.events || 0;

    const isEventsLimitExceeded = events.length >= eventsLimit;

    return { eventsLimit, isEventsLimitExceeded };
  },
);

export const selectHasTrackerAccess = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    if (!subscriptionPlan) return { hasTrackerAccess: false };

    const hasTrackerAccess = subscriptionPlan.category !== "free";

    return { hasTrackerAccess };
  },
);

export const selectHasDashboardAccess = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    if (!subscriptionPlan) return { hasDashboardAccess: false };

    const hasDashboardAccess = subscriptionPlan.category !== "free";

    return { hasDashboardAccess };
  },
);

export const selectHasSubscriptionPlanManageAccess = createDraftSafeSelector(
  (
    state: Store.RootState,
  ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
    selectCompanySubscriptionPlan(state),
  ],
  ([subscriptionPlan]) => {
    if (!subscriptionPlan) return { hasDashboardAccess: false };

    const hasSubscriptionPlanManageAccess =
      subscriptionPlan.category !== "free";

    return { hasSubscriptionPlanManageAccess };
  },
);

export const selectIsCompanyDataUpdateAllowed = createDraftSafeSelector(
  (
    state: Store.RootState,
    companyId: Company.Data["id"],
  ): [ReturnType<typeof selectSubscriptionPlanByCompanyId>] => [
    selectSubscriptionPlanByCompanyId(state, companyId),
  ],
  ([subscriptionPlan]) => {
    if (!subscriptionPlan) return false;

    return subscriptionPlan.isDataUpdateAllowed;
  },
);

export const selectSubscriptionPlanKeywordsDataSources =
  createDraftSafeSelector(
    (
      state: Store.RootState,
    ): [ReturnType<typeof selectCompanySubscriptionPlan>] => [
      selectCompanySubscriptionPlan(state),
    ],
    ([companySubPlan]) => companySubPlan?.keywordsDataSources || [],
  );
